
import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import Personal from '../Personal/index.vue'

@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger,
    Personal,
  },
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar
  }

  get device() {
    return AppModule.device.toString()
  }

  toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  /** 发起背调 */
  onCheck() {
    if (this.$route.name === 'check') {
      // location.reload();
      this.$router.push({ name: 'blank', params: { redirect: 'check' } })
    } else {
      this.$router.push({ name: 'check' })
    }
  }
}
