import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: '/alpha',
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
      meta: { title: '登录', hidden: true },
    },
    {
      path: '/home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
      meta: { title: '门户', hidden: true },
    },
    {
      path: '/contact',
      component: () => import('@/views/contact/index.vue'),
      meta: { title: '联系我们', hidden: true },
    },
    {
      path: '/404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
      meta: { title: '404', hidden: true },
    },
    {
      path: '/',
      component: Layout,
      redirect: '/dashboard',
      // redirect: '/jobs',
      children: [
        {
          path: '/dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
          meta: { title: '首页', icon: 'img-home' },
        },
      ],
    },
    {
      path: '/jobs',
      component: Layout,
      children: [
        {
          name: 'jobs',
          path: '/jobs',
          component: () => import('@/views/jobs/index.vue'),
          meta: { title: '职位管理', icon: 'img-job' },
        },
        {
          name: 'jobAdd',
          path: '/jobs/add',
          component: () => import('@/views/jobs/jobEdit.vue'),
          meta: { title: '新增职位', hidden: true },
        },
        {
          name: 'jobEdit',
          path: '/jobs/:id',
          component: () => import('@/views/jobs/jobEdit.vue'),
          meta: { title: '编辑职位', hidden: true },
        },
      ],
    },
    // {
    //   path: '/candidate',
    //   component: Layout,
    //   children: [
    //     {
    //       name: 'candidate',
    //       path: '/candidate',
    //       component: () => import('@/views/candidate/index.vue'),
    //       meta: {
    //         title: '候选人管理',
    //         icon: 'el-icon-s-home',
    //       },
    //     },
    //   ],
    // },
    {
      path: '/order',
      component: Layout,
      children: [
        {
          name: 'order',
          path: '/order',
          component: () => import('@/views/order/index.vue'),
          meta: { title: '订单管理', icon: 'img-user' },
        },
      ],
    },
    {
      path: '/business',
      component: Layout,
      children: [
        {
          name: 'business',
          path: '/business',
          component: () => import('@/views/business/index.vue'),
          meta: { title: '企业管理', icon: 'img-business' },
        },
      ],
    },
    {
      path: '/own',
      component: Layout,
      children: [
        {
          name: 'own',
          path: '/own',
          component: () => import('@/views/own/index.vue'),
          meta: { title: '资产信息', icon: 'img-own' },
        },
      ],
    },

    {
      path: '/other',
      component: Layout,
      meta: { hidden: true },
      children: [
        {
          name: 'check',
          path: '/check',
          component: () => import('@/views/check/index.vue'),
          meta: { title: '发起背调', icon: 'el-icon-s-home' },
        },
      ],
    },
    {
      path: '/blank',
      component: Layout,
      meta: { hidden: true },
      children: [
        {
          name: 'blank',
          path: '/blank',
          component: () => import('@/views/blank.vue'),
          meta: { title: '空白页' },
        },
      ],
    },
    // {
    //   path: 'external-link',
    //   component: Layout,
    //   children: [
    //     {
    //       path: 'https://www.baidu.com',
    //       meta: {
    //         title: 'External Link',
    //         icon: 'link',
    //       },
    //     },
    //   ],
    // },
    {
      path: '*',
      redirect: '/404',
      meta: { hidden: true },
    },
  ],
})
