/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-own': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M7.906 16.006a.567.567 0 01-.376-.165.523.523 0 01-.152-.376l-.008-4.669-2.426-.004a.507.507 0 01-.375-.152.507.507 0 01-.152-.375l-.01-4.54a.54.54 0 01.534-.534l6.121.01a.54.54 0 01.534.534l.003 4.534a.54.54 0 01-.533.533l-2.63-.004.003 4.12a6.932 6.932 0 006.493-6.914A6.88 6.88 0 0012.9 3.1a6.864 6.864 0 00-4.904-2.032A6.93 6.93 0 004.9 14.198c.264.133.37.449.238.712a.528.528 0 01-.712.237 8.054 8.054 0 01-2.096-1.503A7.961 7.961 0 010 7.995a7.942 7.942 0 012.343-5.652A7.942 7.942 0 017.995.001a7.97 7.97 0 015.662 2.342 7.96 7.96 0 012.349 5.655 7.947 7.947 0 01-2.35 5.659 7.942 7.942 0 01-5.667 2.342.515.515 0 01-.083.007z" _fill="#DEDDDD" fill-rule="nonzero"/><path pid="1" d="M5.337 5.156h5.261a1 1 0 011 1v3.656a1 1 0 01-1 1H5.337a1 1 0 01-1-1V6.156a1 1 0 011-1z" _fill="#919191"/></g>'
  }
})
