
import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'Personal',
})
export default class extends Vue {
  /** 默认头像 */
  avatarDef = require('./icon_user.png')

  get userInfo() {
    return UserModule.userInfo
  }

  async logout() {
    await UserModule.LogOut()
    this.$router.push(`/login?redirect=${this.$route.fullPath}`)
  }
}
