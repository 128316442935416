/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-home': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M15.67 8.268a.318.318 0 01-.224-.093L8 .826.554 8.175a.317.317 0 01-.466-.017.364.364 0 01.017-.494l7.67-7.57a.316.316 0 01.45 0l7.67 7.57c.133.131.14.352.017.494a.322.322 0 01-.242.11z" _fill="#919191"/><path pid="1" d="M14.247 9.623L8.225 3.679a.316.316 0 00-.45 0L1.753 9.623a.36.36 0 00-.105.255v3.777c0 .742.57 1.345 1.27 1.345h10.165c.7 0 1.27-.603 1.27-1.345V9.878a.362.362 0 00-.106-.255z" _fill="#DEDDDD"/><path pid="2" _fill="#FFF" d="M8.571 9.23v4.616H7.43V9.231z"/></g>'
  }
})
