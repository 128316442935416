/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-info': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<g _fill="#999" fill-rule="nonzero"><path pid="0" d="M6 .004a5.996 5.996 0 100 11.992A5.996 5.996 0 006 .004zm0 .922a5.074 5.074 0 110 10.148A5.074 5.074 0 016 .926z"/><path pid="1" d="M6.139 4.386c-.215 0-.413-.088-.52-.231a.371.371 0 010-.461c.107-.143.305-.23.52-.23.331 0 .6.206.6.46 0 .255-.269.462-.6.462zm0 4.382c-.332 0-.6-.207-.6-.462V5.308h1.2v2.998c0 .255-.269.462-.6.462z"/></g>'
  }
})
