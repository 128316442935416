/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-delete': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M0 8a8 8 0 1016 0A8 8 0 000 8z" _fill="#C80000"/><path pid="1" d="M8.41 7.49l2.02-2.02a.571.571 0 11.808.808l-2.02 2.02 2.02 2.02a.571.571 0 11-.809.808l-2.02-2.02-2.02 2.02a.571.571 0 11-.808-.808l2.02-2.02-2.02-2.02a.571.571 0 11.808-.808l2.02 2.02z" _fill="#FFF"/></g>'
  }
})
