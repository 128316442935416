/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-cert': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<g fill-rule="nonzero" _fill="none"><g _fill="#000"><path pid="0" d="M10.367 18.979H1.68c-.924 0-1.676-.672-1.676-1.496V1.502C.003.677.755.006 1.68.006h13.298c.924 0 1.676.671 1.676 1.496V3.8c0 .337-.283.61-.634.61a.622.622 0 01-.634-.61V1.502c0-.132-.169-.28-.408-.28H1.68c-.242 0-.41.148-.41.28V17.48c0 .132.168.279.41.279h8.688a.61.61 0 010 1.22z"/><path pid="1" d="M11.763 6.006H3.894a.625.625 0 01-.634-.612c0-.338.284-.613.634-.613h7.865c.35 0 .634.275.634.613a.624.624 0 01-.63.612zm-3.168 4.26h-4.7a.625.625 0 01-.635-.613c0-.337.284-.613.634-.613h4.701c.35 0 .634.276.634.613a.625.625 0 01-.634.613zm0 4.26h-4.7c-.351 0-.635-.26-.635-.598 0-.337.284-.597.634-.597h4.701c.35 0 .634.26.634.597 0 .337-.283.598-.634.598z"/></g><g _fill="#0064C8" _stroke="#0064C8" stroke-width=".5"><path pid="2" d="M15.5 14.394c-1.928 0-3.496-1.657-3.496-3.694s1.568-3.694 3.496-3.694 3.496 1.657 3.496 3.694-1.568 3.694-3.496 3.694zm0-6.803c-1.622 0-2.942 1.395-2.942 3.11 0 1.713 1.32 3.109 2.942 3.109s2.942-1.396 2.942-3.11-1.32-3.11-2.942-3.11z"/><path pid="3" d="M16.944 14.664v1.47l-1.284-.968a.264.264 0 00-.322 0l-1.282.968v-1.47a3.762 3.762 0 01-.553-.295v2.334c0 .11.058.21.15.26.092.05.203.04.287-.022l1.56-1.176 1.56 1.177a.264.264 0 00.287.022c.092-.05.15-.15.15-.26v-2.333c-.176.112-.361.212-.553.293z"/></g></g>'
  }
})
