/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-user': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M11 9.974c1.855 0 3.358-1.56 3.358-3.486C14.358 4.563 12.855 3.002 11 3 9.144 3 7.64 4.56 7.64 6.486v.002c.001 1.925 1.505 3.486 3.36 3.486zM5.331 12.32C4.669 14.062 3.999 19 3.999 19h5.707L7.99 10.844c-1.034 0-1.995-.264-2.658 1.477zm11.335 0C17.33 14.062 18 19 18 19h-5.707l1.716-8.156c1.033 0 1.995-.264 2.658 1.477zm-2.551 3.016h2.001v-.463h-2.001v.463z" _fill="#DEDDDD" mask="url(#mask-2)" transform="translate(-4 -3)"/><path pid="1" _fill="#919191" mask="url(#mask-2)" d="M11 10.844l-1.62 2.348L10.655 19h.689l1.275-5.808z" transform="translate(-4 -3)"/></g>'
  }
})
