/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-phone': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M4.582 4.77c.21-.212.368-.404.35-.61a.557.557 0 00-.089-.269c-.039-.06-.11-.12-.166-.176l-.333-.335L2.8 1.831l-.353-.355c-.064-.064-.132-.144-.2-.183a.549.549 0 00-.24-.073c-.217-.02-.356.138-.573.355-.233.234-.362.363-.596.595l3.169 3.177c.234-.234.34-.343.575-.577zm2.3 5.3c-.232-.155-.439-.363-.673-.528a21.489 21.489 0 01-.22-.187c-.097-.086-.196-.173-.292-.26l-.31-.284-.077-.077c-.075-.073-.15-.146-.222-.22l-.107-.107-.308-.324-.266-.299-.163-.193c-.13-.182-.288-.347-.407-.525-.282-.427-.558-1-.12-1.43-1.05-1.052-2.12-2.127-3.169-3.177a1.86 1.86 0 00-.198.27 2.482 2.482 0 00-.223.567c-.18.694-.14 1.372.012 2.061.099.44.26.862.448 1.305.13.306.3.596.47.903.133.24.3.47.452.711l.157.22c.158.242.367.46.54.707l.214.254.351.402c.138.146.273.294.41.44l.1.102.381.38.098.1.396.367c.042.039.083.078.125.115l.44.38.407.328.263.198c.246.163.474.346.716.489.287.17.559.338.84.48.296.15.588.277.881.387.132.05.261.098.396.132.168.041.325.094.49.13.6.125 1.227.164 1.833.016.195-.047.388-.105.585-.212.097-.05.265-.158.362-.258l-3.167-3.175c-.462.463-.988.12-1.446-.188zm5.777 1.666c-.04-.068-.115-.134-.181-.2-.107-.107-.214-.216-.323-.322l-1.507-1.51-.337-.338c-.077-.077-.153-.163-.231-.204.154.08-.232-.324-.602-.06l-.864.868c1.026 1.028 2.141 2.148 3.168 3.176.23-.232.356-.361.589-.594.219-.218.377-.366.356-.582a.535.535 0 00-.068-.234zm-2.08-6.012h1.14A3.426 3.426 0 008.3 2.293v1.143c1.258 0 2.28 1.025 2.28 2.288zM8.299.005V1.15a4.568 4.568 0 014.56 4.575H14C14 2.566 11.448.005 8.298.005z" _fill="#B4BCC4" fill-rule="nonzero"/>'
  }
})
