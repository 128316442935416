/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-edu': {
    width: 24,
    height: 21,
    viewBox: '0 0 24 21',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M13.195 4.465h2.994a.53.53 0 01.525.533.53.53 0 01-.525.532h-2.994a.53.53 0 01-.526-.532.53.53 0 01.526-.533zm0 2.648h2.994a.53.53 0 01.525.532.53.53 0 01-.525.532h-2.994a.53.53 0 01-.526-.532.53.53 0 01.526-.532zm.885 2.647c.29 0 .526.239.526.533a.53.53 0 01-.526.532h-.885a.529.529 0 01-.526-.532c0-.294.235-.533.526-.533h.885zm-3.752 2.072l-3.023 1.942-3.023-1.942.815-3.236 2.208 1.359 2.208-1.36.815 3.237zm.566-4.623l.403 3.048h-.018l-.392.233V7.214L7.309 9.42 3.105 6.832l4.2-2.588 4.2 2.588-.611.377z" _fill="#000"/><path pid="1" d="M22 9h-1.2V2a.8.8 0 00-.8-.8H2a.8.8 0 00-.8.8v14a.8.8 0 00.8.8h10V18H2a2 2 0 01-2-2V2a2 2 0 012-2h18a2 2 0 012 2v7z" _fill="#000"/><path pid="2" d="M21.422 17.466a.508.508 0 01.023.021l2.12 2.122a.5.5 0 11-.706.707l-2.122-2.121a.508.508 0 01-.02-.023 4 4 0 11.705-.706zm-1.038-.332a3 3 0 10-4.243-4.243 3 3 0 004.243 4.243z" _fill="#0064C8"/></g>'
  }
})
