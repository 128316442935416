/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-ring': {
    width: 17,
    height: 16,
    viewBox: '0 0 17 16',
    data: '<path pid="0" d="M15.942 13.14c.055.075.083.173.028.247a.27.27 0 01-.25.147H1.28a.302.302 0 01-.25-.123c-.055-.098-.027-.196.028-.27.028-.025 2.166-2.384 2.166-4.768V5.67C3.224 3.089 5.584 1 8.5 1s5.276 2.089 5.276 4.67v2.703c0 2.384 2.138 4.743 2.166 4.768zm-9.302.886h3.72c-.25.712-.971 1.228-1.86 1.228-.889 0-1.61-.516-1.86-1.228z" fill-rule="nonzero" _stroke="#333" stroke-width="1.5" _fill="none"/>'
  }
})
