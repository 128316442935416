import axios from 'axios'
import { ApiConfig, config } from '@panstar/fetch'
// import { ApiConfig, config } from '../../../@panstar/packages/fetch';
import { Message, MessageBox } from 'element-ui'
import { UserModule } from '@/store/modules/user'
export { ajaxApi, ajaxForm, ajaxJson } from '@panstar/fetch'
// export { ajaxApi, ajaxForm, ajaxJson } from '../../../@panstar/packages/fetch';

/** 是否是生产环境 */
const alphaRemote = process.env.VUE_APP_API_PATH
/** 业务应用 */
export const alphaUrl = alphaRemote
/** 后台应用 */
export const betaUrl = '/beta'

/** 初始化API */
export function initApi() {
  config({
    tokenKey: 'x-auth-token',
    beforeRequest: req => {
      req.headers = {
        ...req.headers,
        ...{
          'x-auth-token': UserModule.token,
        },
      }
    },
    failCallback: error => {
      Message({
        message: ((error as unknown) as Error).message,
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(error)
    },
    dealwithResult: (response, options) => {
      return new Promise((resolve, reject) => {
        const res = response.data
        if (res.code !== 0) {
          Message({
            message: res.message || 'Error',
            type: 'error',
            duration: 5 * 1000,
          })
          if (res.code === 203) {
            MessageBox.confirm('登录状态已失效，请重新登录', '登出', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning',
            }).then(() => {
              UserModule.ResetToken()
              location.reload() // To prevent bugs from vue-router
            })
          }
          return reject(new Error(res.message || 'Error'))
        } else {
          console.log('res', res)

          return resolve(res.data)
        }
      })
    },
  } as ApiConfig)
}

/** 接口返回值 */
export type Res<T = any> = {
  /** 错误码 */
  code: number
  /** 数据 */
  data: T
  /** 提示信息 */
  message: string
}
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
})

// Request interceptors
service.interceptors.request.use(
  config => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    if (UserModule.token) {
      config.headers['X-Access-Token'] = UserModule.token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  response => {
    // Some example codes here:
    // code == 20000: success
    // code == 50001: invalid access token
    // code == 50002: already login in other place
    // code == 50003: access token expired
    // code == 50004: invalid user (user not exist)
    // code == 50005: username or password is incorrect
    // You can change this part for your own usage.
    const res = response.data
    if (res.code !== 20000) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        MessageBox.confirm('You have been logged out, try to login again.', 'Log out', {
          confirmButtonText: 'Relogin',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          UserModule.ResetToken()
          location.reload() // To prevent bugs from vue-router
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response.data
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service
