import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { login, logout, getUserInfo, IUserInfo } from '@/api/users'
import { getSysParam, ParamInfo } from '@/api/sys'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import store from '@/store'

export interface IUserState {
  token: string
  introduction: string
  roles: string[]
  /** 用户信息 */
  userInfo: IUserInfo
  /** 助理信息 */
  assistantInfo: ParamInfo
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''
  public userInfo = {} as IUserInfo
  public assistantInfo = {
    wxQrUrl: 'https://web.xcheck360.com/assets/qrcode.png',
  } as ParamInfo

  public introduction = ''
  public roles: string[] = []
  /** 当前账号用户名 */
  username = ''

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  /** 更新用户信息 */
  @Mutation
  private SET_USER_INFO(userInfo: IUserInfo) {
    this.userInfo = userInfo
  }

  @Mutation
  private SET_USERNAME(username: string) {
    this.username = username
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
  }

  @Mutation
  private SET_ASSIST(info: ParamInfo) {
    this.assistantInfo = info
  }

  @Action
  public async Login(userInfo: { username: string; password: string }) {
    let { username, password } = userInfo
    username = username.trim()
    const { token, username: name } = await login({ username, password })

    setToken(token)
    this.SET_TOKEN(token)
    this.SET_USERNAME(name)
  }

  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }

  /** 获取用户信息 */
  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }
    const { ...data } = await getUserInfo()
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }
    const { roles, name, avatar, introduction } = data
    // // roles must be a non-empty array
    // if (!roles || roles.length <= 0) {
    //   throw Error('GetUserInfo: roles must be a non-null array!');
    // }
    // data.name = this.username || '张三';
    this.SET_ROLES(roles || [])
    this.SET_USER_INFO(data)
    this.SET_INTRODUCTION(introduction || '')
    this.getAssistant()
  }

  /** 获取助理信息 */
  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    await logout()
    this.ResetToken()
  }

  @Action
  async getAssistant() {
    const infoList = await getSysParam()
    this.SET_ASSIST(infoList.find(i => i.title === '服务经理') || ({} as ParamInfo))
  }
}

/** 用户状态管理 */
export const UserModule = getModule(User)
