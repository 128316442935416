import { QueryPage, QueryPageResult } from './index'
import { alphaUrl, ajaxApi, ajaxJson } from '@/utils/request'

/** 职位类型 00：社招，01：实习生，02：应届校招，03：兼职招聘 */
enum JobType {
  社招 = '00',
  实习生 = '01',
  应届校招 = '02',
  兼职招聘 = '03',
}
// console.log('JobType', JobType)

/** 职位信息 */
export type JobInfo = {
  /** 职位ID */
  positionId: string
  /** 职位名称 */
  positionName: string
  /** 职位类型 */
  // positionType: JobType
  /** 基础岗位ID */
  baseId: string
  /** 基础岗位的数组 */
  baseIds: string[]
  /** 基础岗位名称 */
  baseName: string
  /** 抄送邮箱 */
  ccEmails: string
  /** 背调负责人账号id */
  contactAcct: string
  /** 职位说明 */
  description: string
  /** 额外调查项 */
  extraItemList: {
    /** 调查项ID */
    surveyId: string
    /** 调查项名称 */
    surveyName: string
  }[]
  /** 招聘网站地址 */
  jdUrl: string
  /** 订单备注 */
  orderRemark: string
  /** 用人部门ID */
  ownerOfficeId: string
  /** 用人部门ID */
  ownerOfficeIds: string[]
  /** 用人部门名称 */
  ownerOfficeName: string
  /** 套餐ID */
  packageId: string
  /** 套餐名称 */
  packageName: string
  /** 候选人数量 */
  candidateNum: number
  /** 已完成数量 */
  finishedOrderNum: number
  /** 授权中数量 */
  inAuthOrderNum: number
  /** 调查中数量 */
  inCheckOrderNum: number
  /** 阶段性完成数量 */
  inReportOrderNum: number
}

type JobQueryPage = QueryPage & Partial<JobInfo>
/** 根据条件查询所有职位信息，查询条件为空时返回公司的所有岗位 */
export const getJobList = (params?: Partial<Pick<JobInfo, 'ownerOfficeId'>>) =>
  ajaxApi('get', alphaUrl + '/cust/position/query/list', params) as Promise<JobInfo[]>

/** 根据用人部门id分页查询职位信息 */
export const getJobPage = (params: JobQueryPage) =>
  ajaxApi('get', alphaUrl + '/cust/position/query/page', params) as Promise<QueryPageResult<JobInfo>>

/** 新增客户职位 */
const addJob = (params: JobInfo) =>
  ajaxJson('post', alphaUrl + '/cust/position/add', params) as Promise<JobInfo['positionId']>
/** 更新客户职位 */
const updateJob = (params: JobInfo) =>
  ajaxJson('post', alphaUrl + '/cust/position/update', params).then(() => params.positionId)
/** 新增/更新客户职位 */
export const saveJob = (params: JobInfo) => {
  return params.positionId ? updateJob(params) : addJob(params)
}
/** 根据id获取客户职位信息 */
export const queryJob = (id: JobInfo['positionId']) =>
  ajaxApi('get', alphaUrl + '/cust/position/query/id', { positionId: id }) as Promise<JobInfo>

/** 删除客户职位 */
export const removeJob = (id: JobInfo['positionId']) =>
  ajaxApi('post', alphaUrl + '/cust/position/delete', { positionId: id })

/** 获取职位类型 */
export const getJobTypeList = () =>
  Promise.resolve([
    { text: '社招', value: '00' },
    { text: '实习生', value: '01' },
    { text: '应届校招', value: '02' },
    { text: '兼职招聘', value: '03' },
  ])
/** 基础岗位 */
export type JobBase = {
  /** 基础岗位id */
  classifyId: string
  /** 基础岗位名称 */
  classifyName: string
  /** 父节点id */
  parentId: string
  children?: JobBase[]
}

/** 获取职位类型，根据id获取下一级的基础岗位列表，id为空时返回第一级的基础岗位列表 */
export const getJobBase = (parentId?: string) =>
  ajaxApi('get', alphaUrl + '/cust/position/query/base', { parentId }) as Promise<JobBase[]>

/** 根据层级返回所有得基础岗位列表 */
export const getJobBaseAll = () => ajaxApi('get', alphaUrl + '/cust/position/query/base/all') as Promise<JobBase[]>
