// 项目公共组件
import InputSearch from './InputSearch.vue';

const Components = [InputSearch];

export default {
  install: function (Vue: any) {
    Components.forEach(i => Vue.component(i.name, i));
  },
};
