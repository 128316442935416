/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-company': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M5.625 0h3.75c.625 0 .938.313.938.938v3.75c0 .625-.313.937-.938.937h-3.75c-.625 0-.938-.313-.938-.938V.937C4.687.314 5 0 5.625 0z" _fill="#919191"/><path pid="1" d="M2.11 7.031h10.78v1.407H2.11V7.03zm0 1.407h1.406v3.75H2.109v-3.75zm9.374 0h1.407v3.75h-1.407v-3.75z" _fill="#DEDDDD"/><path pid="2" _fill="#919191" d="M6.773 3.75H8.18V7.5H6.773z"/><path pid="3" d="M10.313 9.375h3.75c.624 0 .937.313.937.938v3.75c0 .624-.313.937-.938.937h-3.75c-.624 0-.937-.313-.937-.938v-3.75c0-.624.313-.937.938-.937zM.938 9.375h3.75c.625 0 .937.313.937.938v3.75c0 .624-.313.937-.938.937H.937C.314 15 0 14.687 0 14.062v-3.75c0-.624.313-.937.938-.937z" _fill="#DEDDDD"/></g>'
  }
})
