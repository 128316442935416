/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'qr-code': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M489.058 121.905v367.153H121.905V121.905h367.153zm-73.143 73.143H195.048v220.867h220.867V195.048zm-50.2 48.762v121.904H243.81V243.81h121.904zm123.343 291.132v367.153H121.905V534.942h367.153zm-73.143 73.143H195.048v220.867h220.867V608.085zm-50.2 50.2V780.19H243.81V658.286h121.904zm536.38-536.38v367.153H534.942V121.905h367.153zm-73.143 73.143H608.085v220.867h220.867V195.048zM780.19 243.81v121.904H658.286V243.81H780.19zM609.524 828.952v73.143H536.38v-73.143h73.143zm146.286 73.143v-73.143h73.142V731.43h73.143v170.666H755.81zm-73.143-292.571V755.81h73.143v73.142H609.524V609.524h73.143zm146.285 48.762v73.143H755.81v-73.143h73.142zm73.143-121.905v121.905h-73.143v-48.762H755.81V536.38h146.285zm-292.571 0v73.143H536.38V536.38h73.143z"/>'
  }
})
