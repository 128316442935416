/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-work': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<g _fill="#0064C8" fill-rule="evenodd"><path pid="0" d="M11.456 18.03H9.331l.393-1.873a5.63 5.63 0 001.732 1.873zm-.218-9.08l.023-.11c.044 0 .087 0 .13-.002a5.643 5.643 0 00-.153.112zM7.876 7.858c-2.087 0-3.78-1.758-3.781-3.928v-.002C4.097 1.758 5.789 0 7.875 0c2.088.002 3.78 1.76 3.78 3.93s-1.692 3.928-3.78 3.928zM1.5 10.503C2.246 8.541 3.327 8.84 4.49 8.84l1.93 9.19H0s.754-5.563 1.5-7.526zM7.644 8.77l1.687 2.595-1.328 6.42h-.718l-1.329-6.42 1.688-2.595zM12.375 10.738v1.998c.002.99 1.006 1.792 2.246 1.793.596.001 1.17-.187 1.592-.523.423-.337.66-.793.662-1.27v-1.998c0-.992-1.007-1.797-2.25-1.797s-2.25.805-2.25 1.797zm3.437 0v1.998c-.001.521-.53.943-1.183.944-.652 0-1.181-.423-1.182-.944v-1.998c0-.524.531-.948 1.187-.948.655 0 1.186.424 1.186.948h-.008z"/><path pid="1" d="M17.58 13.418c-.267-.03-.51.13-.545.357-.134.872-1.205 1.61-2.342 1.61h-.128c-1.137 0-2.209-.738-2.342-1.61a.433.433 0 00-.297-.331.558.558 0 00-.484.054.392.392 0 00-.188.385c.18 1.171 1.432 2.151 2.883 2.303v.869h-1.074c-.267 0-.484.185-.484.414 0 .228.217.413.484.413h3.131c.268 0 .484-.185.484-.413 0-.229-.216-.414-.484-.414h-1.08v-.869c1.45-.165 2.711-1.132 2.882-2.303a.37.37 0 00-.096-.307.507.507 0 00-.32-.158z"/></g>'
  }
})
